import * as React from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { countryListAlpha2 } from "./countries"
import classnames from "classnames";
import styles from './style.module.scss';

type AddressFormProps = {
  namespace: string;
  countryCode: string;
  streetAddress: string;
  city: string;
  cityArea: string;
  name: string;
  companyName: string;
  countryArea: string;
  sortingCode: string;
  postalCode: string;
};
AddressForm.defaultProps = {
  // TODO for new accounts this should default to the creating @account's country_code
  countryCode: 'US',
  namespace: 'address',
};


function AddressForm(props: AddressFormProps) {
  const [countryCode, setCountryCode] = React.useState(props.countryCode);
  const [countryAreas, setCountryAreas] = React.useState([]);
  const [countryArea, setCountryArea] = React.useState(props.countryArea);
  const [waiting, setWaiting] = React.useState(false);
  const [data, setData] = React.useState<string[][]>([])

  const updateCountry = () => {
    setWaiting(true);
    axios
      .get("/accounts/country_data.json", {
        params: {
          cc: countryCode
        }
      })
      .then(res => {
        setData(res.data["field_order"]);
        setCountryAreas(res.data["country_areas"]);
        setWaiting(false);
      });
  }

  React.useEffect(() => {
    if (countryCode !== undefined) {
      updateCountry();
    }
  }, [countryCode]);


  const FormFields = {
    Name: (
      <Form.Group controlId={`${props.namespace}_name`}>
        <Form.Label>Attention</Form.Label>
        <Form.Control type="text" name={`${props.namespace}[attention]`} defaultValue={props.name} />
      </Form.Group>
    ),
    CompanyName: (
      <Form.Group controlId={`${props.namespace}_company_name`}>
        <Form.Label>Company Name</Form.Label>
        <Form.Control type="text" name={`${props.namespace}[company_name]`} defaultValue={props.companyName} />
      </Form.Group>
    ),
    StreetAddress: (
      <Form.Group controlId={`${props.namespace}_street_address`}>
        <Form.Label>Street Address</Form.Label>
        <Form.Control as="textarea" rows={3} name={`${props.namespace}[street_address]`} defaultValue={props.streetAddress} />
      </Form.Group>
    ),
    City: (
      <Form.Group controlId={`${props.namespace}_city`}>
        <Form.Label>City</Form.Label>
        <Form.Control type="text" name={`${props.namespace}[city]`} defaultValue={props.city} />
      </Form.Group>
    ),
    CityArea: (
      <Form.Group controlId={`${props.namespace}_city_area`}>
        <Form.Label>City Area</Form.Label>
        <Form.Control type="text" name={`${props.namespace}[city_area]`} defaultValue={props.cityArea} />
      </Form.Group>
    ),
    CountryArea: (
      <Form.Group controlId={`${props.namespace}_country_area`}>
        <Form.Label>Country Area</Form.Label>
        {/* note the value/onChange difference here.  bug: https://github.com/react-bootstrap/react-bootstrap/issues/2091 */}
        <Form.Control as="select" name={`${props.namespace}[country_area]`} value={countryArea} onChange={(e) => setCountryArea(e.target.value)}>
          <option value=''></option>
          {countryAreas.map(([key, value], idx) => {
            return (
              <option key={idx} value={key}>{value}</option>
            );
          })}
        </Form.Control>
      </Form.Group>
    ),
    SortingCode: (
      <Form.Group controlId={`${props.namespace}_sorting_code`}>
        <Form.Label>Sorting Code</Form.Label>
        <Form.Control type="text" name={`${props.namespace}[sorting_code]`} defaultValue={props.sortingCode} />
      </Form.Group>
    ),
    PostalCode: (
      <Form.Group controlId={`${props.namespace}_postal_code`}>
        <Form.Label>Postal Code</Form.Label>
        <Form.Control type="text" name={`${props.namespace}[postal_code]`} defaultValue={props.postalCode} />
      </Form.Group>
    ),
  }

  const ComponentMap = {
    "Name": FormFields.Name,
    "CompanyName": FormFields.CompanyName,
    "StreetAddress": FormFields.StreetAddress,
    "City": FormFields.City,
    "CityArea": FormFields.CityArea,
    "CountryArea": FormFields.CountryArea,
    "SortingCode": FormFields.SortingCode,
    "PostalCode": FormFields.PostalCode,
  }

  const Spinner = (
    <span>
      {waiting && <FontAwesomeIcon icon="spinner" spin />}
    </span>
  );

  const Overlay = (
    <React.Fragment>
      {waiting && <div className={styles.overlay} />}
    </React.Fragment>
  );

  const RenderFormFields = (
    <div className={classnames({ [styles.formFields]: true, [styles.waiting]: waiting })}>
      {
        data.map((line, idx_line) => {
          return (
            <Form.Row key={idx_line}>
              {
                line.map((field, idx_field) => {
                  return(<div key={idx_field} className='col'>{ComponentMap[field]}</div>)
                })
              }
            </Form.Row>
          )
        })
      }
      {Overlay}
    </div>
  );

  return (
    <React.Fragment>
      <Form.Group controlId={`${props.namespace}_country_code`}>
        <Form.Label className="mr-2">Country</Form.Label>
        { Spinner}
        <Form.Control as="select" name={`${props.namespace}[country_code]`} value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
          <option value=''></option>
          {Object.entries(countryListAlpha2).map(([key, value], idx) => {
            return (
              <option key={idx} value={key}>{value}</option>
            );
          })}
        </Form.Control>
      </Form.Group>
      {RenderFormFields}
    </React.Fragment>
  );
}

export default AddressForm;